;mobimaker = (function(){
    var C = function(){ return constructor.apply(this, arguments); };
    var mobimaker = C.prototype;

    mobimaker.options = {
        domain: undefined,
        showPreLoader: true,
        template: false,
        production: '',
        development: '',
        vendorsPath: '/adaptive',
        revision: {
            'project.js': 'src/js/project.js',
            'project.css': 'src/css/project.css'
        },
        isMobile: false,
        isDev: false
    };

    function constructor( options ){
        mobimaker.options = mobimaker.merge(mobimaker.options, options);
        mobimaker.options.isDev = mobimaker.urlParam('dev');

        /*mobimaker.onDomReady(function(){
            //mobimaker.setMeta();

            if ( mobimaker.options.showPreLoader ) {
                mobimaker.renderPreLoader();
            }
        });*/

        return mobimaker;
    }

    mobimaker.renderPreLoader = function () {
        console.log('Building Pre-loader');

        document.querySelector("body").style.visibility = 'initial';
        document.querySelector("body").style.display = 'block';

        /*if ( mobimaker.options.isMobile ) {
            var left = screen.width/2 -32;
            var top = screen.height/2 -32;

            mobimaker.preLoaderContainer = document.createElement('div');
            mobimaker.preLoaderContainer.setAttribute("style", "display: block; position: fixed; left: 0; right: 0; bottom: 0; top: 0; background: white; z-index: 100000;");
            mobimaker.preLoaderContainer.innerHTML = '<img src="' + mobimaker.options.vendorsPath + '/img/preloader.gif" style="position: absolute; left: ' + left + 'px; top: ' + top + 'px;">';
            document.getElementsByTagName('body')[0].appendChild(mobimaker.preLoaderContainer);
        }*/
    };

    mobimaker.generateBodyClass = function() {
        var patient = $('body');
        var i = 0;

        if ( window.location.pathname.length > 1 ) {
            var pieces = window.location.pathname.split('/');

            $.each(pieces, function(){
                if ( this != '' ) {
                    patient.attr('data-url-' + i, this);
                    i++;
                }
            });
        } else {
            patient.attr('data-url-' + i, '/');
        }
    };

    mobimaker.setMeta = function() {
        var meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = "width=device-width, initial-scale=1";
        mobimaker.insertAfter(document.getElementById('mobimakerScript'), meta);
    };

    mobimaker.urlParam = function(name){
        var results = new RegExp('[\?&amp;]' + name + '=([^&amp;#]*)').exec(window.location.href);
        if ( ! results ) {
            return false;
        } else {
            return results[1] || 0;
        }
    };

    mobimaker.merge = function(target, source) {

        /* Merges two (or more) objects,
         giving the last one precedence */

        if ( typeof target !== 'object' ) {
            target = {};
        }

        for (var property in source) {

            if ( source.hasOwnProperty(property) ) {

                var sourceProperty = source[ property ];

                if ( typeof sourceProperty === 'object' ) {
                    target[ property ] = util.merge( target[ property ], sourceProperty );
                    continue;
                }

                target[ property ] = sourceProperty;

            }

        }

        for (var a = 2, l = arguments.length; a < l; a++) {
            merge(target, arguments[a]);
        }

        return target;
    };

    mobimaker.isMobile = function() {
        var isMobile = false;
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
            isMobile = true;

        return isMobile;
    };

    mobimaker.onDomReady = function (handler){
        var called = false;

        function ready() {
            if (called) return;
            called = true;
            handler()
        }

        if ( document.addEventListener ) { // native event
            document.addEventListener( "DOMContentLoaded", ready, false )
        } else if ( document.attachEvent ) {  // IE

            try {
                var isFrame = window.frameElement != null
            } catch(e) {}

            // IE, the document is not inside a frame
            if ( document.documentElement.doScroll && !isFrame ) {
                function tryScroll(){
                    if (called) return;
                    try {
                        document.documentElement.doScroll("left");
                        ready()
                    } catch(e) {
                        setTimeout(tryScroll, 10)
                    }
                }
                tryScroll()
            }

            // IE, the document is inside a frame
            document.attachEvent("onreadystatechange", function(){
                if ( document.readyState === "complete" ) {
                    ready()
                }
            })
        }

        // Old browsers
        if (window.addEventListener)
            window.addEventListener('load', ready, false);
        else if (window.attachEvent)
            window.attachEvent('onload', ready);
        else {
            var fn = window.onload; // very old browser, copy old onload
            window.onload = function() { // replace by new onload and call the old one
                fn && fn();
                ready()
            }
        }
    };

    mobimaker.insertAfter = function ( referenceNode, newNode ) {
        referenceNode.parentNode.insertBefore( newNode, referenceNode.nextSibling );
    };

    mobimaker.cssValue = function ( str ) {
        return parseInt(str, 10);
    };

    return C;
})();

function insertAfter ( n ) {
    var r = document.getElementById("mobimakerScript");
    r.parentNode.insertBefore( n, r.nextSibling );
}

/*var preLoader = document.createElement("style");
preLoader.innerHTML = "body { visibility: hidden; }";
insertAfter(preLoader);*/

window.mobimakerApi = mobimaker({
    showPreLoader: false,
    domain: 'prof-medicina.ru'
});