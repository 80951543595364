$(mobimakerApi).bind('mm.ready', function() {
    if ( $(window).width() <= 1180 ) {
        //new mobimaker.CopyRights.Init();

        $('body[data-url-0="forum"] #mcenter').after($('body[data-url-0="forum"] #header .exmenu-row #exmenu'));
        $('body[data-url-0="forum"] #header .exmenu-row').css('display', 'none');

        $('#account input[name="login"]').attr('placeholder', 'Email');
        $('#account input[name="pwd"]').attr('placeholder', 'Password');

        /*new mobimaker.ContentToggle.Init(
            $('.story-box h3'),
            $('.story-box .graybox>div:not(:first)'),
            {
                collapsed: true
            }
        );

        new mobimaker.ContentToggle.Init(
            $('.expert_titul_box h3'),
            $('.expert_titul_box .items>div:not(:first), .expert_titul_box .footer'),
            {
                collapsed: true
            }
        );

        new mobimaker.ContentToggle.Init(
            $('body.titul .list-view h3'),
            $('body.titul .list-view .articles>div:not(:first), .list-view .footer'),
            {
                collapsed: true
            }
        );

        new mobimaker.ContentToggle.Init(
            $('.forumtheme-box h3'),
            $('.forumtheme-box .item:not(:first), .forumtheme-box .footer'),
            {
                collapsed: true
            }
        );

        $('.news-box').each(function() {
            var el = $(this);
            new mobimaker.ContentToggle.Init(
                el.find('h3'),
                el.find('.item:not(:first), .footer'),
                {
                    collapsed: true
                }
            );
        });*/

        /*var menu = new mobimaker.Menu.Init({
            logo: '<a href="/"><img class="logo" style="height: 38px;" src="' + mobimakerApi.options.vendorsPath + '/img/logo.png"></a>',
            email: 'admin@prof-medicina.ru',
            target: $('#doc'),
            searchAction: '/search'
            //phone: '+892039587'
        });

        $('#mainmenu .l1 .a1').each(function() {
            var container = $(this).parents('td.l1');
            var subMenu = [];

            if ( $('ul.l2', container).length ) {
                $('ul.l2 .a2', container).each(function() {
                    var subSubMenu = [];

                    if ( $('ul.l3', $(this).parents('li.l2')).length ) {
                        $('ul.l3 .a3', $(this).parents('li.l2')).each(function() {
                            subSubMenu.push({
                                text: $(this).text(),
                                link: $(this).attr('href')
                            });
                        });
                    }

                    subMenu.push({
                        text: $(this).text(),
                        link: $(this).attr('href'),
                        subMenu: subSubMenu,
                        subMenuOpened: true
                    });
                });
            }

            menu.collection.add({
                text: $(this).text(),
                link: $(this).attr('href'),
                specialClasses: container.hasClass('active') ? "active" : '',
                subMenu: subMenu
            });
        });*/

        /*function bannerSize () {
            $('div.gpt-ad').each(function() {
                var banner = $("iframe", $(this));
                var bannerContent = banner.contents();

                bannerContent.find("img").css({
                    'display': 'inline-block',
                    'max-width': '100%',
                    'height': 'auto'
                });

                bannerContent.find('#ad_wrapper').css({
                    'width': '100%',
                    'text-align': 'center'
                });

                banner.css({
                    'height': bannerContent.find("img").height()
                });
            });
        }

        bannerSize();
        setInterval(function(){
            bannerSize();
        }, 500);*/

        //$('body .editor-content table.none tr').each(function(){
        //    if ( $(this).text().replace(/\s{2,}/g, '') == '' ) {
        //        $(this).remove();
        //    }
        //});
        //
        //$('body .editor-content table.none tr td').each(function(){
        //    if ( $(this).text().replace(/\s{1,}/g, '').replace(/\s{2,}/g, '').length == 0 ) {
        //        $(this).remove();
        //    }
        //});

        $('body[data-url-0="surgeon"][data-url-2="price"] .editor-content table')
            .stacktable({});

        // FORUM
        var forum = $('#forum');
        var forumList = $('.forum-list', forum);
        var forumThemes = $('.forumtheme-list', forum);

        if ( forumList.text().replace(/\s{1,}/g, '').replace(/\s{2,}/g, '').length != 0 )
            forumList.prepend('<h1>Ветки форума</h1>');

        if ( forumThemes.text().replace(/\s{1,}/g, '').replace(/\s{2,}/g, '').length != 0 )
            forumThemes.prepend('<h1>Темы форума</h1>');

        // forumList
        if ( ! $('>table.forums tr.head', forumList).length ) {
            $('.forum-list>table.forums').prepend(
                '<thead><tr>' +
                '<td></td>' +
                '<td>Сообщений:</td>' +
                '<td>Последнее сообщение от:</td>' +
                '</tr></thead>'
            );
        }

        $('>table', forumList).stacktable({});

        $('>table.large-only thead', forumList).remove();

        $('>table.small-only td.st-val', forumList).each(function(){
            var html = $(this).html();
            html = html.replace(/Сообщений:<br>/g, "");
            html = html.replace(/Последнее сообщение от/g, "");
            $(this).html(html);
        });


        // forumThemes
        $('>table.forumthemes', forumThemes).stacktable({});
        $('>table.forumthemes.small-only tr', forumThemes).removeClass('forumtheme-top');


        $('>table.forumthemes .st-head-row', forumThemes).each(function() {
            if ( $(this).text() == '' ) {
                $(this).remove();
            }
        });

        /**
         * Modify an existing stylesheet.
         * - sheetId - the id of the <link> or <style> element that defines the stylesheet to be changed
         * - selector - the id/class/element part of the rule.  e.g. "div", ".sectionTitle", "#chapter2"
         * - property - the CSS attribute to be changed.  e.g. "border", "font-size"
         * - value - the new value for the CSS attribute.  e.g. "2px solid blue", "14px"
         */
        function changeCSS(sheetFileName, selector, property, value){
            var s = $('link[rel="stylesheet"][href*="' + sheetFileName + '"]')[0].sheet;
            var rules = s.cssRules || s.rules;
            for(var i = rules.length - 1, found = false; i >= 0 && !found; i--){
                var r = rules[i];
                if(r.selectorText == selector){
                    r.style.setProperty(property, value);
                    r.style.removeProperty(property);
                    found = true;
                }
            }
            if(!found){
                s.insertRule(selector + '{' + property + ':' + value + ';}', rules.length);
            }
        }

        //changeCSS('main.css', '.myDialog .photo_show__slider .mainphoto .slick-slide', 'width', 'initial');


        //$('body[data-url-1="akcii"] table.tborder').stacktable({});

        /*setInterval(function(){
            if ( $('.slick-slider').length )
                $('.slick-slider').slick('setPosition');
        }, 100);*/
    }

    if ( $(window).width() <= 640 ) {
        var el1 = $('body[data-url-1="akcii"] table.tborder tr:first-child>td:last-child');
        var clone1 = el1.clone();
        el1.remove();

        var el2 = $('body[data-url-1="akcii"] table.tborder tr:last-child>td:last-child');
        var clone2 = el2.clone();
        el2.remove();

        $('body[data-url-1="akcii"] table.tborder tr:first-child').append(el2);
        $('body[data-url-1="akcii"] table.tborder tr:last-child').prepend(el1);


        //uvelichenie-grudi.htm
        var bodyClasses = [
            'uvelichenie-grudi.htm', 'umenshenie-grudi.htm', 'podtyazhka-grudi.htm', 'blefaroplastica.htm', 'liposakziya.htm',
            'abdominoplastica.htm', 'rinoplastica.htm', 'facelifting.htm', 'otoplastica.htm', 'gluteoplastica.htm',
            'cruroplastica.htm', 'udalenie-rubtsov.htm', 'podtiazhka-beder.htm', 'zolotie-niti.htm', 'podtyajka-aptos-nityami.htm',
            'siluetlift.htm', 'correctsiya-oreol.htm', 'plastica-podborodka.htm'
        ];

        $.each(bodyClasses, function( index, value ){
            if ( $('body[data-url-0="' + value + '"]').length ) {
                var el1 = $('body[data-url-0="' + value + '"] #mleft #leftmenu');
                var clone1 = el1.clone();
                el1.remove();

                var el2 = $('body[data-url-0="' + value + '"] #mright .photo-box');
                var clone2 = el2.clone();
                el2.remove();

                var el3 = $('body[data-url-0="' + value + '"] #mleft .review-box');
                var clone3 = el3.clone();
                el3.remove();

                $('body[data-url-0="' + value + '"] #mcenter .article-list').before(el1);
                $('body[data-url-0="' + value + '"] #mcenter .article-list').before(el2);
                $('body[data-url-0="' + value + '"] #mcenter .article-list').before(el3);
            }
        });
    }
});

$(mobimakerApi).bind('disableMobi', function() {
    //new mobimaker.CopyRights.Init();

    $('#footer').css({
        'bottom': '80px'
    });

    $('.mobimaker-copy-rights').css({
        'padding-bottom': '20px',
        'text-align': 'center'
    }).find('a').css({
        'color': '#333333',
        'text-decoration': 'underline',
        'font-size': '16px'
    });
});


(function($) {
    $.fn.smClone = function() {
        var el = $(this);
        var clone = el.clone().removeClass('h-sm').addClass('h-lg h-md');
        el.addClass('h-sm');
        return clone;
    };

    $.fn.mdClone = function() {
        var el = $(this);
        var clone = el.clone().removeClass('h-md').addClass('h-lg h-sm');
        el.addClass('h-md');
        return clone;
    };

    $.fn.lgClone = function() {
        var el = $(this);
        var clone = el.clone().removeClass('h-md h-sm').addClass('h-lg');
        el.addClass('h-md h-sm');
        return clone;
    };
})(jQuery);

(function() {
    function get_cookie ( cookie_name )
    {
        var results = document.cookie.match ( '(^|;) ?' + cookie_name + '=([^;]*)(;|$)' );

        if ( results )
            return ( unescape ( results[2] ) );
        else
            return null;
    }

    var fullversion = get_cookie('fullversion');

    var fn = function(adaptive) {
        if(adaptive=='md' || adaptive=='sm') {
            if(!fullversion) {
                $(mobimakerApi).trigger('mm.ready');
                $('<a class="version_selector i" href="#" onclick="setCookie(\'fullversion\', 1, 31536000, \'/\');location.reload();return false;">Полная версия</a>').appendTo('#top .userexit');
            }
            return true;
        } else {
            //$(mobimakerApi).trigger('disableMobi');
        }
    };

    $(function() {
        $.adaptive(fn);

        $('#leftmenu').smClone().addClass('slide-menu closed').appendTo('#doc');
        $('<div class="toggle" />').appendTo('.slide-menu').click(function() {
            var menu = $(this).closest('.slide-menu');
            if(menu.hasClass('closed')) {
                menu.addClass('opened').removeClass('closed');
            } else {
                menu.removeClass('opened').addClass('closed');
            }
        });

        (function($) {
            if(!document.addEventListener || !document.createEvent) return;

            var clickms = 200;
            var lastTouchDown = -1;
            var lastX, lastY = 0;

            function touchHandler(event) {
                var touches = event.changedTouches,
                    first = touches[0],
                    type = "";

                var d = new Date();
                switch(event.type)	{
                    case "touchstart": lastTouchDown = d.getTime(); lastX = first.clientX; lastY = first.clientY; break;
                    case "touchmove": lastTouchDown = -1; break;
                    case "touchend":
                        if(lastTouchDown > -1 && (d.getTime() - lastTouchDown) < clickms){lastTouchDown = -1; break;}

                        var simulatedEvent = document.createEvent("MouseEvent");
                        simulatedEvent.initMouseEvent('swipe', true, true, window, 1,
                            first.screenX, first.screenY,
                            first.clientX, first.clientY, false,
                            false, false, false, 0, null);
                        first.target.dispatchEvent(simulatedEvent);

                        if(Math.abs(first.clientY-lastY)<50) {
                            if(Math.abs(first.clientX-lastX)>50) {
                                if(first.clientX>lastX) {
                                    var simulatedEvent = document.createEvent("MouseEvent");
                                    simulatedEvent.initMouseEvent('swiperight', true, true, window, 1,
                                        first.screenX, first.screenY,
                                        first.clientX, first.clientY, false,
                                        false, false, false, 0, null);
                                    first.target.dispatchEvent(simulatedEvent);
                                } else {
                                    var simulatedEvent = document.createEvent("MouseEvent");
                                    simulatedEvent.initMouseEvent('swipeleft', true, true, window, 1,
                                        first.screenX, first.screenY,
                                        first.clientX, first.clientY, false,
                                        false, false, false, 0, null);
                                    first.target.dispatchEvent(simulatedEvent);
                                }
                            }
                        }

                        break;
                    default: return;
                }
            }

            document.addEventListener("touchstart", touchHandler, true);
            document.addEventListener("touchmove", touchHandler, true);
            document.addEventListener("touchend", touchHandler, true);
            document.addEventListener("touchcancel", touchHandler, true);
        })(jQuery);

        $(document).on("swiperight",function(event){
            if($(event.target).closest('.myDialog').length || $(event.target).closest('.price-slider').length)
                return;
            $('.slide-menu').addClass('opened').removeClass('closed');
        });
        $(document).on("swipeleft",function(event){
            if($(event.target).closest('.myDialog').length || $(event.target).closest('.price-slider').length)
                return;
            $('.slide-menu').removeClass('opened').addClass('closed');
        });

    });
})();