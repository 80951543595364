ContentToggle = {
    Views: {},
    Models: {}
};

/*************************************************************************************
 MODELS
 *************************************************************************************/

ContentToggle.Models.Options = Backbone.Model.extend({
    defaults: {
        wrap: true,
        animationDuration: 300,
        collapsed: false
    }
});

/*************************************************************************************
 INIT
 *************************************************************************************/

ContentToggle.Init = Backbone.View.extend({
    initialize: function (toggle, content, options) {
        this.toggle = toggle;
        this.content = content;
        this.model = new ContentToggle.Models.Options(options);

        this.render();

        $('button.mobimaker-content-toggle', this.toggle).click(this.toggleEvent.bind(this));

        this.model.on('change:collapsed', this.collapse, this);
    },

    render: function() {
        if ( this.model.get('wrap') ) {
            this.content.wrapAll('<div class="mobimaker-content-toggle"></div>');
            this.content = this.content.parents('.mobimaker-content-toggle');
        }

        this.toggle.append('<button class="mobimaker-content-toggle"></button>');
        this.collapse();
    },

    toggleEvent: function(e) {
        e.preventDefault();

        if ( this.model.get('collapsed') ) {
            this.model.set('collapsed', false);
        } else {
            this.model.set('collapsed', true);
        }
    },

    collapse: function() {
        if ( this.model.get('collapsed') ) {
            if ( this.model.get('wrap') ) {
                this.content.slideUp(this.model.get('animationDuration'));
            } else {
                this.content.fadeOut(0);
            }

            $('button.mobimaker-content-toggle', this.toggle).addClass('active');
        } else {
            if ( this.model.get('wrap') ) {
                this.content.slideDown(this.model.get('animationDuration'));
            } else {
                this.content.fadeIn(0);
            }

            $('button.mobimaker-content-toggle', this.toggle).removeClass('active');
        }
    }
});

mobimaker.ContentToggle = ContentToggle;